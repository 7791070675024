<template>
  <div class="text-black h-page mt-3 mx-3">
    <div class="flex justify-end text-2xl">
      <i @click="close" class="el-icon-close cursor-pointer"></i>
    </div>
    <div class="p-3 pt-0 fs-14 fw-500">
      <div class="ml-3 mb-1 text-xl fw-600" v-if="user">
        <span class="mr-1">{{ user.name }}</span>
        <span>{{ getInfo() }}</span>
      </div>
      <div class="ml-3 text-base" v-if="hr">
        <div class="mb-1">
          Mã SKB:
          <span class="ml-2">{{ hr.code }}</span>
        </div>
        <div>
          Lý do:
          <span class="ml-7">{{ hr.visit_reason }}</span>
        </div>
        <div class="mt-1">
          Ngày tạo:
          <span class="">{{ formatDate(hr.created_at) }}</span>
        </div>
      </div>
      <div v-loading="loading">
        <el-table
          :data="hrList"
          @row-click="viewDetail"
          class="w-full mt-3"
          row-class-name="text-black text-sm cursor-pointer"
          header-cell-class-name="text-black text-base font-semibold"
        >
          <el-table-column
            label="Ngày khám"
            :formatter="formatDateDMY"
            width="300px"
          >
          </el-table-column>
          <el-table-column
            :formatter="getReason"
            prop="reason"
            label="Lý do khám"
          >
          </el-table-column>
          <el-table-column label="Kết luận" :formatter="conclusion">
          </el-table-column>
          <el-table-column
            prop="diagnose"
            label="Ngày tạo"
            :formatter="(row) => appUtils.formatTimeDate(row && row.created_at)"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import moment from 'moment'
export default {
  name: 'HealthRecordList',
  components: {},
  data () {
    return {
      hr: null,
      hrList: null,
      loading: false,
      user: null,
      hrId: this.$route.params.id,
      appUtils
    }
  },
  mounted () {
    this.user = this.$user
    this.getListPatientVisitById()
  },
  methods: {
    viewDetail (row) {
      this.$router.push({ path: `/hr/pv/${row.id}` })
    },
    close () {
      this.$router.push({ name: 'HealthRecords' })
    },
    async getListPatientVisitById () {
      try {
        this.loading = true
        const resp = await this.$rf
          .getRequest('AuthRequest')
          .getListPatientVisitById(this.hrId)
        this.hr = resp.data
        this.hrList = resp.data?.patient_visist
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    formatDateDMY (row) {
      return (
        row.start_time && moment(row.start_time).format('HH:mm DD/MM/YYYY')
      )
    },
    conclusion (row) {
      return row?.prehistoric || 'Chưa có kết luận'
    },
    getReason (row) {
      return row.visit_reason
    },
    getInfo () {
      let result = '('
      if (this.user.person?.gender) {
        result = `${result}${appUtils.getGender(this.user.person?.gender)}`
      }
      if (this.user.person?.age) result = `${result} - ${this.user.person.age}`
      if (this.user.person?.address) {
        result = `${result} - ${this.user.person.address}`
      }
      return result + ')'
    },
    formatDate (date) {
      return date && moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>

        <style lang='scss' scoped>
.h-page {
  min-height: calc(100vh - 150px);
}
::v-deep {
  .el-table .cell {
    word-break: break-word;
  }
}
</style>
